import React from 'react';
import { Router } from '@reach/router';
import ToolDetail from '../pages/tool-detail';
import ToolsHome from '../pages/tools';

const App = () => {
  return (
    <>
      <Router basepath="/">
        <ToolDetail path="/tools/:id" />
        <ToolsHome path="/tools" />
      </Router>
    </>
  );
};
export default App;
